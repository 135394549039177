<template>
    <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

        <div class="container-fluid py-4">
            <div class="row">
                <div class="cal-lg-8">
                    <div class="row">
                        <div class="cal-xl-6 mb-xl-0 mb-4">
                            <div class="card bg-transparent shadow-xl">
                                <div class="overflow-hidden position-relative border-radius-xl">
                                    <span class="mask bg-gradient-dark opacity-10"></span>
                                    <div class="card-body position-relative z-index-1 p-3">
                                        <div class="d-flex flex-row justify-content-between">
                                            <div>
                                                <p class="text-white text-sm opacity-8 mb-0">Balance</p>
                                                <h5 class="text-white mb-5 pb-2">R {{auth.user.balance | currency}}</h5>
                                            </div>
                                            <div>
                                                <router-link to="/top-up" tag="a" class="btn bg-gradient-primary mb-0 mt-1">
                                                    <i class="material-icons text-sm">add</i>&nbsp;&nbsp;TOP UP
                                                </router-link>
                                            </div>
                                        </div>
                                        <div class="d-flex">
                                            <div class="d-flex">
                                                <div class="me-4">
                                                    <p class="text-white text-sm opacity-8 mb-0">Student</p>
                                                    <h6 class="text-white mb-0">{{auth.user.firstName}} {{auth.user.lastName}}</h6>
                                                </div>
                                                <div>
                                                    <p class="text-white text-sm opacity-8 mb-0">PIN <button class="btn btn-xs mb-0 pt-0 ps-1 pb-0 " v-on:click="showPin = !showPin">{{showPin ? 'hide' : 'show'}}</button></p>
                                                    <h6 class="text-white mb-0" v-if="showPin">{{auth.user.pin}}</h6>
                                                    <h6 class="text-white mb-0" v-else>***** </h6>
                                                </div>
                                            </div>
                                            <div class="ms-auto w-20 d-flex align-items-end justify-content-end">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class=" py-2 pb-4">
                    <div class="card">
                        <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                            <input type="radio" v-model="mode" value="transactions" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked>
                            <label class="btn btn-outline-primary mb-0" for="btnradio1">Transactions</label>

                            <input type="radio" v-model="mode" value="pricing" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off">
                            <label class="btn btn-outline-primary mb-0" for="btnradio2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pricing&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="cal-lg-4 mx-auto">
                    <transition name="slide-left">

                        <!-- #region Transactions -->
                        <div class="card h-100 child-view-alt" v-if="mode == 'transactions'">
                            <div class="card-header pb-0 px-3">
                                <div class="row align-content-center">
                                    <div class="col-12 d-flex justify-content-between">
                                        <div class="col-6 justify-content-start pb-2">

                                            <h6 class="mb-0">Your Transaction's</h6>
                                        </div>
                                        <div class="col-auto">
                                            <button class="btn btn-primary mb-0 px-2 py-0 w-100" v-on:click="getTransactions">
                                                <i class="material-icons me-sm-1" aria-hidden="true">refresh</i> refresh
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-12 d-flex justify-content-start align-items-center">
                                        <i class="material-icons me-2 text-lg">date_range</i>
                                        <small>{{startAt | dayMonth}} - {{endAt | dayMonthYear}}</small>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body p-3 pb-0">
                                <ul class="list-group">
                                    <template v-for="(trn, idx) in transactions.transactions">
                                        <div :key="`trn-${idx}`" class="mb-3 ">
                                            <div class="d-flex flex-row justify-content-between">
                                                <div class="d-inline-flex">
                                                    <h6 class="mb-0 text-dark font-weight-bold text-sm">
                                                        <span class=" text-sm">
                                                            <i class="material-icons position-relative  text-xs text--success" v-show="trn.transactionType === 'kiosk'">north</i>
                                                            <i class="material-icons position-relative  text-xs text--error" v-show="trn.transactionType !== 'kiosk'">south</i>
                                                        </span>
                                                        {{trn.transactionDateUtc | time}} <span class="text-xs">{{trn.transactionDateUtc | monthDayYear}}</span>
                                                    </h6>
                                                </div>
                                                <div class="d-inline-flex">
                                                    <div class="text-sm">
                                                        <span>R <span class="text-dark mb-0 font-weight-bold text-sm">{{trn.amount | currency}}</span></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-auto truncate">
                                                    <span class="text-xs ">
                                                        {{trn.description}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </ul>
                            </div>
                        </div>
                        <!-- #endregion -->

                    </transition>
                    <transition name="slide-left">
                        <!-- #region Pricing -->
                        <div class="card h-100 child-view-alt" v-if="mode == 'pricing'">
                            <div class="card-header pb-0 px-3">
                                <div class="row">
                                    <div class="col-md-6">
                                        <h6 class="mb-0">Pricing</h6>
                                    </div>
                                    <!--<div class="col-12 d-flex justify-content-start align-items-center">
                                        <i class="material-icons me-2 text-lg">money</i>
                                        <small>Current printing prices</small>
                                    </div>-->
                                </div>
                            </div>
                            <div class="card-body pt-4 p-3">
                                <h6 class="text-uppercase text-body text-xs font-weight-bolder mb-3">Black and White</h6>
                                <ul class="list-group">
                                    <printing-price-list-item text="Single Sided A4" :price="pricing.bwSingleSidedA4"></printing-price-list-item>
                                    <printing-price-list-item text="Double Sided A4" :price="pricing.bwDoubleSidedA4"></printing-price-list-item>
                                    <printing-price-list-item text="Single Sided A3" :price="pricing.bwSingleSidedA3"></printing-price-list-item>
                                    <printing-price-list-item text="Double Sided A3" :price="pricing.bwDoubleSidedA3"></printing-price-list-item>
                                </ul>
                                <h6 class="text-uppercase text-body text-xs font-weight-bolder my-3">Colour</h6>
                                <ul class="list-group">
                                    <printing-price-list-item text="Single Sided A4" :price="pricing.colourSingleSidedA4" is-color></printing-price-list-item>
                                    <printing-price-list-item text="Double Sided A4" :price="pricing.colourDoubleSidedA4" is-color></printing-price-list-item>
                                    <printing-price-list-item text="Single Sided A3" :price="pricing.colourSingleSidedA3" is-color></printing-price-list-item>
                                    <printing-price-list-item text="Double Sided A3" :price="pricing.colourDoubleSidedA3" is-color></printing-price-list-item>
                                </ul>
                            </div>
                        </div>
                        <!-- #endregion -->
                    </transition>
                </div>
            </div>
            <footer class="footer py-4">
                <div class="container-fluid">
                    <div class="row align-items-center justify-content-lg-between">
                        <div class="cal-lg-6 mb-lg-0 mb-4">
                            <div class="copyright text-center text-sm text-muted taxt-lg-start">
                                <!--<button v-on:click="signalrtest">clicking me</button>-->
                                ⚡ By
                                <a href="https://www.wizebooks.co.za" class="font-weight-bold" target="_blank">Wize Books</a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    </main>
</template>

<script>
    import PrintingPriceListItem from '@/components/printing-price-list-item';
    import { mapState, mapActions } from 'vuex'
    export default {
        components: {
            PrintingPriceListItem
        },
        data: function () {
            return {
                loading: false,
                startAt: null,
                endAt: null,
                transactions: {},
                connection: null,
                mode: 'transactions',
                pricing: {},
                showPin: false
            };
        },
        created: function () {
            this.connection = new this.$signalR.HubConnectionBuilder()
                .withUrl("/userHub")
                .build();
        },
        methods: {
            ...mapActions(['setBalance']),
            signalrtest: async function () {
                let model = {
                    token: this.auth.token,
                    email: this.auth.user.email,
                    startAt: this.startAt.toJSON().substring(0, 10),
                    endAt: this.endAt.toJSON().substring(0, 10)
                };
                await this.$http.post('/api/Transaction/Test', model)
            },
            getTransactions: async function () {
                this.loading = true;
                let model = {
                    token: this.auth.token,
                    studentNumber: this.auth.user.studentNumber,
                    startAt: this.startAt.toJSON().substring(0, 10),
                    endAt: this.endAt.toJSON().substring(0, 10)
                };
                let result = await this.$http.post('/api/Transaction/GetTransactions', model)
                if (result.status == 200) {
                    this.transactions = result.data
                }
                this.loading = false;
            },
            getPricing: async function () {
                this.loading = true;
                let result = await this.$http.get('/api/Pricing/GetPricing')
                if (result.status == 200) {
                    this.pricing = result.data
                }

                this.loading = false;
            },
            getBalance: async function () {
                this.loading = true;
                let model = {
                    token: this.auth.token,
                    email: this.auth.user.email
                };
                let result = await this.$http.post('/api/User/GetBalance', model);
                if (result.status == 200) {
                    this.setBalance(result.data.currentBalance);
                }
                this.loading = false;
            }
        },
        computed: {
            ...mapState(['auth'])
        },
        mounted: function () {
            this.startAt = new Date();
            this.startAt.setMonth(this.startAt.getMonth() - 2);
            this.endAt = new Date();
            this.getTransactions();
            this.getPricing();
            this.getBalance();
            this.connection.on('balanceUpdate', data => {
                if (this.auth.token == data) {
                    this.getBalance();
                    this.getTransactions();
                }
            });
            this.connection.start();
        }
    }
</script>
<style>
    .truncate {
        width: 75vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .text--error {
        color: #F44336 !important;
    }

    .text--success {
        color: #4CAF50 !important;
    }
</style>